import { DatePipe, DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder } from '@angular/forms';

import { UserDataService } from '../../services/user-data.service';
import { environment } from '../../environments/environment';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import { CryptoService } from '../../services/crypto.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { AppState } from '../../services/app.state';
import { FcmService } from 'src/app/services/fcm.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('container') containerRef: ElementRef;

  @ViewChild('fileUploadInput')
  fileUploadInput: ElementRef;
  filename: string = '';

  links = ['My Chats', 'Pending', 'Idle'];
  activeLink: string = this.links[0];

  search: string = '';
  message: any;
  isSelectedUser: any;
  isLoading: boolean = false;
  isLoadingbtn: boolean = false;
  AssetsURL: string = environment.assetsURL;
  InitalCharacterURL: string = environment.initialCharacterURL;
  fromMe: boolean = true;

  sendMsgForm = this.fb.group({
    message: [''],
  });
  count_array: any = [];

  pendingUsers: any[] = [];
  myChatUsers: any[] = [];
  idleUsers: any[] = [];

  tempPendingUsers: any[] = [];
  tempMyChatUsers: any[] = [];
  tempIdleUsers: any[] = [];

  totalUnreadMsgMyChat = 0;
  totalUnreadMsgPending = 0;
  totalUnreadMsgIdle = 0;

  chatMessages: any[] = [];
  selectedUser: any;
  loggedInUser: any;
  firebaseUsersCollection = '';
  srcURL = false;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private fireStorage: AngularFireStorage,
    private datepipe: DatePipe,
    private cryptoService: CryptoService,
    private commanService: CommonService,
    private userDataService: UserDataService,
    private router: Router,
    private appState: AppState,
    private fcmService: FcmService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.firebaseUsersCollection = environment.firebaseUsersCollection;
    this.loggedInUser = this.userDataService.getUserData();
    this.loadUsers();
    this.getChatUnreadMsgTotal();
  }

  handleClick() {
    this.fileUploadInput.nativeElement.value = '';
    this.fileUploadInput.nativeElement.click();
  }
  customerDetails(row: any) {
    this.appState.set('CUSTOMER', row);
    this.router.navigate(['/details-customer']);
  }
  generateSecureRandomString(length = 12) {
    const array = new Uint32Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, (num) => num.toString(36))
      .join('')
      .substring(0, length);
  }

  upload(event: any) {
    this.isLoadingbtn = true;
    this.filename = event.target.files[0].name;
    const id = this.selectedUser.id + '/' + this.generateSecureRandomString();
    // const id =
    //   this.selectedUser.id + '/' + Math.random().toString(36).substring(2);
    let ref: AngularFireStorageReference = this.fireStorage.ref(id);
    let task: AngularFireUploadTask = ref.put(event.target.files[0]);
    let mimeType = event.target.files[0]['type'];
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          let downloadURL = ref.getDownloadURL();
          downloadURL.subscribe((url) => {
            if (url) {
              this.isLoadingbtn = false;
              let encryptedUrl = this.encryptMessage(url);
              this.sendNotification('File');
              let msg = {
                from: 'support',
                to: 'user',
                message: '',
                attachment_url: encryptedUrl,
                mime_type: mimeType,
                timestamp: new Date().getTime(),

                support_id: this.loggedInUser.cognitoId,
                support_name: this.loggedInUser.email,
                support_read: true,

                user_id: this.selectedUser.id,
                user_name: this.selectedUser.user_name,
                user_read: false,
              };

              this.afs
                .collection(this.firebaseUsersCollection)
                .doc(this.selectedUser.id + '')
                .collection('support_chat')
                .add(msg);
              this.fileUploadInput.nativeElement.value = '';
              this.filename = '';
            }
          });
        })
      )
      .subscribe((url) => {});
  }

  loadUsers() {
    this.afs
      .collection(this.firebaseUsersCollection, (ref: any) =>
        ref
          .where('status', '==', 'assigned')
          .where('current_support_id', '==', this.loggedInUser.cognitoId)
      )
      .snapshotChanges()
      .subscribe((snapshots: any) => {
        this.myChatUsers = snapshots.map((a: any) => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          data.unreadMsgCount = '';
          data.profile_img =
            this.InitalCharacterURL +
            '/coverages-icons/' +
            data.user_name.charAt(0).toLowerCase() +
            '-enabled.png';
          let date = new Date(data.last_message_timestamp);
          data.last_message_timestamp = this.datepipe.transform(
            date,
            'hh:mm a'
          );
          return { id, ...data };
        });
        // this.activeUser();
        this.tempMyChatUsers = this.myChatUsers;
      });

    this.afs
      .collection(this.firebaseUsersCollection, (ref: any) =>
        ref.where('status', '==', 'waiting')
      )
      .snapshotChanges()
      .subscribe((snapshots: any) => {
        this.pendingUsers = snapshots.map((a: any) => {
          const data = a.payload.doc.data() as any;
          data.unreadMsgCount = '';
          //data.profile_img = this.InitalCharacterURL + 'coverages-icons/' + data.user_name ? data.user_name.charAt(0).toLowerCase() : '' + '-enabled.png'
          // let date = new Date(data.last_message_timestamp);
          data.last_message_timestamp = this.datepipe.transform(
            data.last_message_timestamp,
            'hh:mm a'
          );
          const id = a.payload.doc.id;
          return { id, ...data };
        });
        this.tempPendingUsers = this.pendingUsers;
        // this.isSelectedUser = this.pendingUsers.length > 0 ? this.pendingUsers[0].id:0;
      });

    this.afs
      .collection(this.firebaseUsersCollection, (ref: any) =>
        ref.where('status', '==', 'idle')
      )
      .snapshotChanges()
      .subscribe((snapshots: any) => {
        this.idleUsers = snapshots.map((a: any) => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          data.unreadMsgCount = '';
          data.profile_img =
            this.InitalCharacterURL +
            '/coverages-icons/' +
            data.user_name.charAt(0).toLowerCase() +
            '-enabled.png';
          let date = new Date(data.last_message_timestamp);
          data.last_message_timestamp = this.datepipe.transform(
            date,
            'hh:mm a'
          );
          return { id, ...data };
        });
        this.tempIdleUsers = this.idleUsers;
        // this.isSelectedUser = this.idleUsers.length > 0 ? this.idleUsers[0].id : 0;
      });
  }
  searchUser(event: any) {
    let name = event.target.value.trim();
    if (event.target.value.trim() == '') {
      this.myChatUsers =
        this.activeLink == 'My Chats' ? this.tempMyChatUsers : this.myChatUsers;
      this.pendingUsers =
        this.activeLink == 'Pending'
          ? this.tempPendingUsers
          : this.pendingUsers;
      this.idleUsers =
        this.activeLink == 'Idle' ? this.tempIdleUsers : this.idleUsers;
    } else {
      if (this.activeLink == 'My Chats' && this.tempMyChatUsers.length > 0) {
        this.myChatUsers = [];
        // this.tempMyChatUsers.find(user => user.user_name.toLowerCase().includes(name.toLowerCase()))
        this.myChatUsers = this.tempMyChatUsers.filter((user) =>
          user.user_name.toLowerCase().includes(name.toLowerCase())
        );
      } else if (
        this.activeLink == 'Pending' &&
        this.tempPendingUsers.length > 0
      ) {
        this.pendingUsers = [];
        this.pendingUsers = this.tempPendingUsers.filter((user) =>
          user.user_name.toLowerCase().includes(name.toLowerCase())
        );
      } else if (this.activeLink == 'Idle' && this.tempIdleUsers.length > 0) {
        this.idleUsers = [];
        this.idleUsers = this.tempIdleUsers.filter((user) =>
          user.user_name.toLowerCase().includes(name.toLowerCase())
        );
      }
    }
  }
  tabChange(link: any) {
    this.activeLink = link;
    this.selectedUser = null;
    this.search = '';
    this.activeUser();
  }
  activeUser(id: any = 0) {
    this.isSelectedUser = 0;
    this.isSelectedUser = id != 0 ? id : this.isSelectedUser;
    if (this.activeLink == 'My Chats' && this.myChatUsers.length > 0) {
      // this.isSelectedUser = this.myChatUsers.length > 0 ? this.myChatUsers[0].id : 0;
      this.loadChats(this.myChatUsers[0]);
    } else if (this.activeLink == 'Pending' && this.pendingUsers.length > 0) {
      // this.isSelectedUser = this.pendingUsers.length > 0 ? this.pendingUsers[0].id : 0;
      this.loadChats(this.pendingUsers[0]);
    } else if (this.activeLink == 'Idle' && this.idleUsers.length > 0) {
      // this.isSelectedUser = this.idleUsers.length > 0 ? this.idleUsers[0].id : 0;
      this.loadChats(this.idleUsers[0]);
    }
    if (
      (this.activeLink == 'My Chats' && this.myChatUsers.length == 0) ||
      (this.activeLink == 'Pending' && this.pendingUsers.length == 0) ||
      (this.activeLink == 'Idle' && this.idleUsers.length == 0)
    ) {
      this.selectedUser = null;
      this.isSelectedUser = 0;
    }
  }
  downloadFile(file: any, index: any) {
    if (
      file.mime_type == 'image/png' ||
      file.mime_type == 'image/jpg' ||
      file.mime_type == 'image/jpeg'
    ) {
      let filename =
        file.mime_type == 'image/png'
          ? `image${index}.png`
          : file.mime_type == 'image/jpg'
          ? `image${index}.jpg`
          : `image${index}.jpeg`;
      this.commanService
        .download(file.attachment_url)
        .subscribe((blob: any) => {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = filename;
          a.click();
          URL.revokeObjectURL(objectUrl);
        });
      // FileSaver.saveAs(url, filename);
    } else {
      let filename = `attachment${index}.pdf`;
      this.commanService
        .downloadPdf(file.attachment_url)
        .subscribe((pdf: any) => {
          const a = document.createElement('a');
          let file = new Blob([pdf], { type: 'application/pdf' });
          const objectUrl = URL.createObjectURL(file);
          a.href = objectUrl;
          a.download = filename;
          a.click();
          URL.revokeObjectURL(objectUrl);
        });
      // FileSaver.saveAs(url, "attachment.pdf");
    }
  }
  getChatUnreadMsgTotal() {
    this.afs
      .collection(this.firebaseUsersCollection, (ref: any) =>
        ref.where('current_support_id', '==', this.loggedInUser.cognitoId)
      )
      .snapshotChanges()
      .subscribe((snapshot: any) => {
        let user = [];
        user = snapshot.map((a: any) => {
          const data = a.payload.doc.data() as any;
          let ref = a;
          return { ref, ...data };
        });
        if (user.length > 0) {
          this.totalUnreadMsgPending = 0;
          this.totalUnreadMsgMyChat = 0;
          this.totalUnreadMsgIdle = 0;
          user.map((u: any) => {
            u.ref.payload.doc.ref
              .collection('support_chat')
              .orderBy('timestamp', 'asc')
              .onSnapshot((as: any) => {
                if (as.docs && as.docs.length > 0) {
                  this.count_array = [];
                  as.docs.filter((d: any, i: any) => {
                    let data = d.data();
                    if (data.from == 'user' && data.support_read == false) {
                      // this.count_array.length > 0 ? this.count_array.find((x :any )=>x.id == data.id) ?
                      // '': this.count_array.push(data):
                      this.count_array.push(data);
                    }
                  });
                  if (
                    this.activeLink == 'My Chats' &&
                    this.count_array.length > 0
                  ) {
                    let index = this.myChatUsers.findIndex(
                      (user) => user.id == u.id
                    );

                    if (index >= 0) {
                      this.myChatUsers[index].unreadMsgCount =
                        this.count_array.length;
                      let total: any = this.myChatUsers.map((u) => {
                        if (
                          u.unreadMsgCount != '' &&
                          u.unreadMsgCount != null &&
                          u.unreadMsgCount != undefined
                        ) {
                          return u.unreadMsgCount;
                        } else {
                          return 0;
                        }
                      });
                      total = total.reduce((a: any, b: any) => a + b);
                      this.totalUnreadMsgMyChat = total;
                    }
                  }
                  if (
                    this.activeLink == 'Pending' &&
                    this.count_array.length > 0
                  ) {
                    let index = this.pendingUsers.findIndex(
                      (user) => user.id == u.id
                    );
                    this.pendingUsers[index].unreadMsgCount =
                      this.count_array.length;
                    let total: any = this.pendingUsers.map((u) => {
                      if (
                        u.unreadMsgCount != '' &&
                        u.unreadMsgCount != null &&
                        u.unreadMsgCount != undefined
                      ) {
                        return u.unreadMsgCount;
                      } else {
                        return 0;
                      }
                    });
                    // if (u == undefined || u == null) { total.splice(i); }
                    let t = 0;
                    total = total.reduce((a: any, b: any) => a + b);
                    this.totalUnreadMsgPending = total;
                  }
                  if (
                    this.activeLink == 'Idle' &&
                    this.count_array.length > 0
                  ) {
                    let index = this.idleUsers.findIndex(
                      (user) => user.id == u.id
                    );
                    this.idleUsers[index].unreadMsgCount =
                      this.count_array.length;
                    let total: any = this.idleUsers.map((u) => {
                      if (
                        u.unreadMsgCount != '' &&
                        u.unreadMsgCount != null &&
                        u.unreadMsgCount != undefined
                      ) {
                        return u.unreadMsgCount;
                      } else {
                        return 0;
                      }
                    });
                    total = total.reduce((a: any, b: any) => a + b);
                    this.totalUnreadMsgIdle = total;
                  }
                }
              });
          });
        }
      });
  }
  loadChats(user: any) {
    this.isSelectedUser = user.id;
    this.selectedUser = user;
    this.isLoadingbtn = false;
    const c: any = this.afs
      .collection(this.firebaseUsersCollection, (ref: any) =>
        ref.where('id', '==', user.id)
      )
      .snapshotChanges()
      .subscribe((snapshot: any) => {
        let user = snapshot[0].payload.doc.data();

        if (user.id == this.selectedUser.id) {
          /**
           * TODO .limit(5)
           */
          this.chatMessages = [];
          snapshot[0].payload.doc.ref
            .collection('support_chat')
            .orderBy('timestamp', 'asc')
            .onSnapshot((as: any) => {
              if (as.docs && as.docs.length > 0) {
                let id = as.docs[0].ref.parent.parent.id;
                if (this.selectedUser.id == id) {
                  this.chatMessages = [];

                  as.docs.forEach((d: any) => {
                    let id = d.ref.parent.parent.id;
                    if (this.selectedUser.id == id) {
                      let chatMsg = d.data();

                      // var name=chatMsg.from == "support" ? chatMsg.support_name.charAt(0).toLowerCase() : '';
                      chatMsg.profile_img =
                        chatMsg.from == 'user'
                          ? this.InitalCharacterURL +
                            '/coverages-icons/' +
                            chatMsg.user_name.charAt(0).toLowerCase() +
                            '-enabled.png'
                          : this.InitalCharacterURL +
                            '/coverages-icons/' +
                            chatMsg.support_name.substring(0, 1).toLowerCase() +
                            '-enabled.png';

                      if (!chatMsg.support_read) {
                        chatMsg['support_read'] = true;
                        this.afs
                          .collection('uat_users')
                          .doc(this.selectedUser.id + '')
                          .collection('support_chat')
                          .doc(d.id)
                          .update(chatMsg);
                        this.loadUsers();
                        this.getChatUnreadMsgTotal();
                      }
                      this.chatMessages.push(chatMsg);
                    }
                  });

                  this.scrollToBottom();
                }
              }
            });
        }
      });
  }

  // auto_height(elem:any) {
  //   elem.style.height = "1px";
  //   elem.style.height = (elem.scrollHeight) + "px";
  // }

  // send msg calling this message
  createNewMessage() {
    let messageText = this.sendMsgForm.value.message;
    if (messageText && messageText.trim().length > 0) {
      // this.isLoadingbtn=true;
      let encryptedMsg = this.encryptMessage(messageText);

      let msg = {
        from: 'support',
        to: 'user',
        attachment_url: '',
        mime_type: '',
        message: encryptedMsg,
        timestamp: new Date().getTime(),

        support_id: this.loggedInUser.cognitoId,
        support_name: this.loggedInUser.email,
        support_read: true,

        user_id: this.selectedUser.id,
        user_name: this.selectedUser.user_name,
        user_read: false,
      };

      this.afs
        .collection(this.firebaseUsersCollection)
        .doc(this.selectedUser.id + '')
        .collection('support_chat')
        .add(msg);

      this.sendMsgForm.reset();

      if (this.selectedUser.token && this.selectedUser.token.length > 0) {
        this.sendNotification(messageText);
      }
    }
  }

  assign() {
    this.isSelectedUser = this.selectedUser.id;
    this.activeLink = this.links[0];

    let supportInfo = {
      current_support_id: this.loggedInUser.cognitoId,
      support_name: this.loggedInUser.email,
      status: 'assigned',
    };
    this.selectedUser.status = 'assigned';

    this.afs
      .doc(this.firebaseUsersCollection + '/' + this.selectedUser.id)
      .update(supportInfo);
  }

  complete() {
    let supportInfo = {
      current_support_id: null,
      support_name: null,
      status: 'idle',
    };
    this.selectedUser.status = 'idle';

    this.afs
      .doc(this.firebaseUsersCollection + '/' + this.selectedUser.id)
      .update(supportInfo);
  }

  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13 && event.shiftKey) {
      this.createNewMessage();
    }
  }

  getDate(created: any) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'yyyy-MM-dd h:mm:ss a');
  }

  scrollToBottom() {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  decryptMessage(msg: string) {
    return this.cryptoService.getDecryptedChat(msg);
  }

  encryptMessage(msg: string) {
    return this.cryptoService.getEncryptedChat(msg);
  }

  sendNotification(msgText: string) {
    let payload: any = {};

    if (this.selectedUser.device_type == 'android') {
      payload = {
        data: {
          notif_type: 'chat',
          notification: {
            title: 'Support',
            body: msgText,
          },
        },
        to: this.selectedUser.token,
      };
    } else if (this.selectedUser.device_type == 'ios') {
      payload = {
        data: {
          notif_type: 'chat',
        },
        notification: {
          title: 'Support',
          body: msgText,
        },
        to: this.selectedUser.token,
      };
    }

    let response = this.fcmService.sendNotification(payload);
    response?.subscribe(
      (res) => {},
      (err) => {}
    );
  }
}
