<div class="row ml-1 mr-1 verification-of-insurance-main">
  <div class="col-md-5 pdf-viewer">
    <div fxLayout fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
      <mat-form-field *ngIf="documents && documents.length && !isLoading" appearance="outline">
        <mat-label>Insurance</mat-label>
        <mat-select [formControl]="insurancePDFselector" (selectionChange)="insurancePDFChange()">
          <mat-option *ngFor="let pdf of documents;let i=index;" [value]="i">Insurance - {{i+1}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button *ngIf="!(documents && documents.length > 0)" class="btn btn-yolow btn-md font-family-NSB mt-2"
        (click)="handleClick()"><span>Upload Document</span></button>
      <input type="file" #UploadFile id="fileUpload" style="display: none;" (change)="uploadDocument($event)"
        name="fileUpload" accept=".pdf" />
      <div class="h-40" fxLayoutGap="5px">

      </div>

      <div fxLayoutAlign="end" fxLayoutGap="5px" *ngIf="pdfSrc">
        <button mat-icon-button (click)="download()" title="Download" class="btn download_icon_main btn-sm">
          <img [src]="download_svg" alt="yolow logo image" class="download_icon">
        </button>
        <button mat-icon-button (click)="incrementZoom(0.1)" title="Zoom In" class="btn btn-outline-dark btn-sm ">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button (click)="incrementZoom(-0.1)" title="Zoom Out" class="btn btn-outline-dark btn-sm">
          <mat-icon>zoom_out</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout fxLayout="column">
      <!-- <div fxLayout fxLayoutAlign="center" fxLayoutGap="30px" *ngIf="isLoading">
        <mat-spinner style="margin:0 auto;" *ngIf="isLoading" [diameter]="30"></mat-spinner>
      </div> -->
      <span *ngIf="images.length > 0 && !isLoading">
        <ngx-image-viewer [src]="images" (customEvent)="handleEvent($event)">
        </ngx-image-viewer>
      </span>
      <span *ngIf="pdfSrc != ''">
        <pdf-viewer [src]="pdfSrc" [autoresize]="true" [fit-to-page]="true" [rotation]="0" [stick-to-page]="false"
          [show-all]="true" [zoom]="zoom" [external-link-target]="'blank'" [zoom-scale]="'page-fit'"
          [render-text]="true" style="display: block;" *ngIf="pdfSrc"></pdf-viewer>
      </span>
    </div>
  </div>

  <div class="col-md-7 verification-of-insurance font-family-NSB">
    <!-- <span *ngIf="isLoadingUser">
      <mat-spinner style="margin:50px auto;" *ngIf="isLoadingUser" [diameter]="30"></mat-spinner>
    </span> -->
    <div fxLayout style="align-items: center !important; gap: 10px;">
      <h5 class="card-title ml-1 font-family-NB mt-4" fxLayout fxFlex="97">Verification of Insurance</h5>
      <button mat-button type="submit" class="btn btn-yolow btn-md radius-50 font-family-NSB"
        (click)="formAction($event)">{{ formDisabled ? 'Edit' : 'Save' }}</button>


      <!-- <button *ngIf="isDisableSave; else elseBlock" (click)="onClick()">Click Me</button>

      <ng-template #elseBlock>
        <button (click)="onAlternateClick()">Alternate Click</button>
      </ng-template> -->

      <button mat-button type="button" class="btn btn-outline-yellow btn-md radius-50 font-family-NSB"
        *ngIf="!formDisabled" (click)="resetFormValues()">Cancel</button>
    </div>


    <form [formGroup]="formGroup" class="policy-details" #myForm="ngForm">
      <h4 class="policy-heading">Policy Details</h4>
      <div class="policy-form">
        <h5>Insured Name:</h5>
        <input type="text" matInput formControlName="insuredName" placeholder="Enter Insured Name" />
      </div>
      <div class="policy-form">
        <h5>Property Address:</h5>
        <input type="text" matInput formControlName="propertyAddress" placeholder="Enter Property Address" />
      </div>
      <div class="policy-form">
        <h5>Policy Number:</h5>
        <input type="text" matInput formControlName="policyNumber" placeholder="Enter Policy Number" />
      </div>
      <div class="policy-form">
        <h5>Policy Term:</h5>
        <input type="text" matInput formControlName="policyTerm" placeholder="Enter Policy Term" />
      </div>
      <div class="policy-form">
        <h5>Premium Amount:</h5>
        <input type="text" matInput formControlName="premiumAmount" placeholder="Enter Premium Amount" />
      </div>
      <div class="policy-form">
        <h5>Insurance Start Date:</h5>
        <input type="text" matInput formControlName="insuranceStartDate" placeholder="Enter Insurance Start Date" />
      </div>
      <div class="policy-form">
        <h5>Initial Payment Details:</h5>
        <input type="text" matInput formControlName="initialPaymentDetails"
          placeholder="Enter Initial Payment Details" />
      </div>
      <div class="policy-form">
        <h5>Renewal Details:</h5>
        <input type="text" matInput formControlName="renewal_Details" placeholder="Enter Renewal Details" />
      </div>
      <div class="policy-form">
        <h5>Insurer Name, Contact:</h5>
        <input type="text" matInput formControlName="insurerNameContact" placeholder="Enter Contact" />
      </div>
      <div class="policy-form">
        <h5>Agent Name/ Agency Name, Contact:</h5>
        <input type="text" matInput formControlName="agencyNameContact" placeholder="Enter Agent Info" />
      </div>
      <div class="policy-form">
        <h5>Mortgagee Clause :</h5>
        <input type="text" matInput formControlName="mortgagee_clause" placeholder="Enter Mortgagee Clause" />
      </div>
      <!-- <ng-container formArrayName="mortgagee_details">
        <ng-container *ngFor="let mortgagee_detail of mortgagee_details?.controls; let i=index">
          <ng-container [formGroup]="mortgagee_detail">
            <h5 class="policy-form-title">Mortgagee detail {{i + 1}}</h5>
            <div class="policy-form">
              <h5>Mortgagee info</h5>
              <input type="text" *ngIf="mortgagee_details?.controls[i]?.controls?.mortgagee" matInput
                formControlName="mortgagee" placeholder="Enter Mortgagee Details" />
            </div>
            <div class="policy-form">
              <h5>Loan number</h5>
              <input type="text" *ngIf="mortgagee_details?.controls[i]?.controls?.loan_number" matInput
                formControlName="loan_number" placeholder="Enter Loan Number Details" />
            </div>
          </ng-container>
        </ng-container>
      </ng-container> -->
      <h5 class="policy-form-title">Mortgagee detail </h5>
      <div class="policy-form">
        <h5>Mortgagee info :</h5>
        <input type="text" matInput formControlName="valid_mortgagee_info" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Mortgagee info Validation :</h5>
        <input type="text" matInput formControlName="valid_mortgagee_info_message" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Loan number :</h5>
        <input type="text" matInput formControlName="valid_loan_number" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Loan number Validation:</h5>
        <input type="text" matInput formControlName="valid_loan_number_message" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Borrower Name :</h5>
        <input type="text" matInput formControlName="valid_borrower_name" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Borrower Validation :</h5>
        <input type="text" matInput formControlName="valid_borrower_name_message" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Co borrower Name :</h5>
        <input type="text" matInput formControlName="valid_co_borrower_name" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Co borrower Validation:</h5>
        <input type="text" matInput formControlName="valid_co_borrower_name_message" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Property Address :</h5>
        <input type="text" matInput formControlName="valid_property_address" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Property Address Validation :</h5>
        <input type="text" matInput formControlName="valid_property_address_message" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Loan Amount :</h5>
        <input type="text" matInput formControlName="valid_loan_amount" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Loan Amount Validation :</h5>
        <input type="text" matInput formControlName="valid_loan_amount_message" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Closing Date :</h5>
        <input type="text" matInput formControlName="valid_closing_date" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Closing Date Validation :</h5>
        <input type="text" matInput formControlName="valid_closing_date_message" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Validation Summary :</h5>
        <input type="text" matInput formControlName="validation_summary" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Dwelling Limit :</h5>
        <input type="text" matInput formControlName="dwelling_limit" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Dwelling Deductible :</h5>
        <input type="text" matInput formControlName="dwelling_deductible" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Other Structure Limit :</h5>
        <input type="text" matInput formControlName="other_structure_limit" placeholder="" />
      </div>
      <div class="policy-form">
        <h5>Other Structure Deductible :</h5>
        <input type="text" matInput formControlName="other_structure_deductible" placeholder="" />
      </div>
    </form>

    <div class="policy-details-footer">
      <button class="white-outline-button" (click)="generatePDF()">Save & Generate
        PDF</button>
      <button class="white-outline-button" [disabled]="!this.viewPDFsrc" (click)="viewPDF()">View
        PDF</button>
      <button class="white-outline-button" [disabled]="!this.viewPDFsrc" (click)="openSendMailDialogue()">Send
        Email</button>
      <button class="white-outline-button" [disabled]="!this.viewPDFsrc" (click)="onSendIVS()">Send
        IVS</button>
      <button class="white-outline-button" [disabled]="!this.isFormValid"
        (click)="openStatusNotificationDialogue()">Notify Status</button>
      <button class="white-outline-button" [disabled]="!(this.registeredEmails.length > 0)"
        (click)="viewEmailStatus()">View Email
        Status</button>
    </div>
  </div>
</div>